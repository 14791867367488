import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function AboutUs({ data: { strapi: { aboutUs } }, location }) {

	return (
		<>
			<SEO title="About us" description={ aboutUs.seoDescription } pathname={ location.pathname } />

			<div className="content-page content-page--has-header">
				<div className="wrapper">
					<div className="content-page__header">
						<h1 className="content-page__heading">Welcome to alitus</h1>
					</div>
				</div>

				{ aboutUs && aboutUs.content &&
					<DynamicZone components={ aboutUs.content } />
				}
			</div>

		</>
	)
}

export const query = graphql`
	query aboutUsPageQuery {
		strapi {
			aboutUs {
				seoDescription
				content {
					... on StrapiQuery_ComponentCommonVideo {
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						displayBorder
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentCommonStatement {
						id
						statement
						url
						urlText
					}
					... on StrapiQuery_ComponentCommonTestimonial {
						id
						introduction
						testimonial {
							slug
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonContactForm {
						id
						message
					}
					... on StrapiQuery_ComponentCommonDivider {
						id
						display
					}
					... on StrapiQuery_ComponentAboutWiderFamily {
						id
						heading
						families {
							id
							logo
							main
							name
							summary
							url
						}
					}
				}
			}
		}
	}
`